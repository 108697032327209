import React from 'react'
import Image from 'next/image';
import Heading from 'atoms/Heading';
import Text from 'atoms/Text';
import Button from 'atoms/Button';
import { useRouter } from 'next/router'

const NotFound = () => {
    const router = useRouter();
  return (
    <div className="md:max-w-[694px] md:mx-auto px-5 w-full mt-14 mb-20 text-center">
        <Image src="/images/notfound.svg" height="454" width="489" />
        <Heading fontWeight="font-bold" type="h1" className="mt-6"> Oops! Page Not Found </Heading>
        <Text fontWeight="font-medium" variant="body" className="mt-2 text-neutral-500">
         "Lost in the digital wilderness! The page you're seeking has vanished. Navigate back home and restart your journey.
        </Text>
        <Button
          className="text-center mt-8"
          style="primary"
          label="Take Me Home"
          fullWidth
          onClick={() => router.push(`/`)}
        />
    </div>
  )
}

export default NotFound;